import dayjs from 'dayjs';
import React, { useState, useMemo, useEffect } from 'react';
import {
  useForm,
  FormProvider,
  Controller,
} from 'react-hook-form';
import {
  useQuery,
  useMutation,
} from 'react-query';
import { useParams } from 'react-router-dom';

import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Loading from 'components/atoms/Loading';
import Text from 'components/atoms/Text';
import TextArea from 'components/atoms/Textarea';
import ProcessTitle from 'components/molecules/ProcessTitle';
import ResultItem from 'components/molecules/ResultItem';
import SectionTitle from 'components/molecules/SectionTitle';
import Signature from 'components/molecules/Signature';
import InfoCard from 'components/organisms/InfoCard';
import CustomModal from 'components/organisms/Modal';
import Table from 'components/organisms/Table';
import NotifyModal from 'components/templates/NotifyModal';
import PageLayout from 'components/templates/PageLayout';
import {
  getEreportServices,
  postEreportServices,
} from 'services/eReport';

const STATUS = {
  NOT_APPROVE_YET: 0,
  APPROVE: 1,
  NOT_APPROVE: 2,
};

const SPECIAL_PROJECT_CODE = 'mua-tri-an-2024';

const dataHeaderReportTable = [
  // { field: 'startOfTheHourAndEnter', headerName: 'SL kế hoạch' },
  { field: 'type', headerName: 'Mục đích sử dụng' },
  { field: 'endOfTheHour', headerName: 'SL' },
];

const dataSamplingHeader = [
  { field: 'product', headerName: 'II. KẾT QUẢ SAMPLING (ĐVT)' },
  { field: 'type', headerName: 'Loại Sampling' },
  { field: 'amount', headerName: 'SL' },
];

type ReportFormType = {
  note: string;
  file: string;
};

type PopupHeader = {
  field: string;
  headerName: string;
};

type PopupData = {
  product?: string;
  startOfTheHourAndEnter?: number;
  endOfTheHour?: number;
};

const Home: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [reportStatus, setReportStatus] = useState<number>(0);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [note, setNote] = useState('');
  const [isNotFound, setIsNotFound] = useState(false);
  const [fileError, setFileError] = useState('');
  const [errorModal, setErrorModal] = useState({
    title: '',
    open: false,
    message: '',
  });
  const [units, setUnits] = useState({
    big: 'thùng',
    small: 'lon',
  });
  const { slug: token, key } = useParams();

  const method = useForm<ReportFormType>({
    mode: 'onChange',
    defaultValues: {
      note: '',
      file: '',
    },
  });

  const watchFile = method.watch('file');

  const { data: eReportRes, isFetching } = useQuery(
    ['getEreportServices', token, key],
    () => getEreportServices({
      token: token || '',
      key: key || '',
    }),
    {
      enabled: !!token,
      onError: (err) => {
        if (Array.isArray(err) && err.length > 0) {
          if (err[0].code === 404) {
            setIsNotFound(true);
          }
        }
      }
    },
  );

  useEffect(() => {
    if (eReportRes?.project?.code === SPECIAL_PROJECT_CODE) {
      setUnits({
        big: 'đơn vị lớn',
        small: 'đơn vị nhỏ',
      });
    }
  }, [eReportRes]);

  const dataHeaderReportKPITable = [
    { field: 'product', headerName: 'Sản phẩm' },
    { field: 'amount', headerName: `SL ${units.big}` },
    { field: 'unit', headerName: `SL ${units.small}` },
    // { field: 'kpi', headerName: 'KPI' },
  ];

  const infoCard = useMemo(() => {
    if (eReportRes) {
      return [
        {
          title: 'Tên chương trình',
          description: eReportRes?.project?.name,
        },
        {
          title: 'Tỉnh/Thành',
          description: eReportRes?.province?.name,
        },
        {
          title: 'Tên Outlet',
          description: eReportRes?.outlet?.name,
        },
        {
          title: 'Mã Outlet',
          description: eReportRes?.outlet?.code,
        },
        {
          title: 'Địa chỉ',
          description: eReportRes?.outlet?.address,
        },
        {
          title: 'Thời gian thực hiện',
          description: dayjs(eReportRes?.time?.start).format('DD-MM-YYYY'),
        },
        {
          title: 'Đơn vị thực hiện',
          description: eReportRes?.implementing_agencies,
        },
      ];
    }
    return [];
  }, [eReportRes]);

  const dataTableKPI = useMemo(() => {
    if (eReportRes && eReportRes.kpi_report.has_gift_items.length > 0) {
      let totalKpiCartonSafe = 0;
      let totalKpiBottleCan = 0;
      const table = eReportRes.kpi_report.has_gift_items.map((item) => {
        totalKpiCartonSafe += Number(item.total_carton_safe);
        totalKpiBottleCan += Number(item.total_bottle_can);
        return ({
          product: item.name,
          amount: item.total_carton_safe,
          unit: item.total_bottle_can,
          // kpi: item.total_kpi_sales,
        });
      });

      const tableNoneGift = eReportRes.kpi_report.none_gift_items.map((item) => {
        totalKpiCartonSafe += Number(item.total_carton_safe);
        totalKpiBottleCan += Number(item.total_bottle_can);
        return ({
          product: item.name,
          amount: item.total_carton_safe,
          unit: item.total_bottle_can,
          // kpi: item.total_kpi_sales,
        });
      });

      return {
        totalKpiCartonSafe,
        totalKpiBottleCan,
        table,
        tableNoneGift,
      };
    }
    return {
      totalKpiCartonSafe: 0,
      totalKpiBottleCan: 0,
      table: [],
    };
  }, [eReportRes]);

  const dataPopup = useMemo(() => {
    let header: PopupHeader[] = [];
    let data: PopupData[] = [];
    let comment = '';
    if (eReportRes) {
      header = [
        { field: 'product', headerName: 'I. QUÀ TẶNG (ĐVT)' },
        ...dataHeaderReportTable
      ];
      data = eReportRes?.gift_report?.items?.map((item) => ({
        product: item?.name || '',
        type: item?.kind_of_gift || '',
        // startOfTheHourAndEnter: item.total_kpi,
        endOfTheHour: item?.total,
      })) || [];
      comment = eReportRes?.gift_report?.comment || '';
    }
    return {
      header,
      data,
      comment,
    };
  }, [eReportRes]);

  const dataSampling = useMemo(() => {
    if (eReportRes?.samplings && eReportRes.samplings.length > 0) {
      return eReportRes.samplings.map((item) => ({
        product: item.name,
        type: item.type,
        amount: item.quantity,
      }));
    }
    return [];
  }, [eReportRes]);

  const { mutate: updateMutate, isLoading } = useMutation(postEreportServices, {
    onSuccess: () => {
      setNote(method.getValues('note'));
      setIsOpen(true);
      setIsSubmitted(true);
      method.reset();
    },
    onError: () => {
      setErrorModal({
        title: 'Thất bại',
        open: true,
        message: 'Gửi thất bại! Vui lòng thử lại!',
      });
    },
    onSettled: () => {
      setIsModalOpen(false);
    }
  });

  const handleSubmit = async (data: ReportFormType, status: number) => {
    if (token && key) {
      if (data.file) {
        const blob = await fetch(data?.file).then((res) => res.blob());
        const file = new File([blob], `signature-${data.file.length}.jpeg`, { type: 'image/jpeg' });
        updateMutate({
          token,
          key,
          status,
          note: data.note,
          file,
        });
      } else {
        updateMutate({
          token,
          key,
          status,
          note: data.note,
        });
      }
    }
  };

  const handleClick = (statusNumber: number) => {
    if (statusNumber !== STATUS.NOT_APPROVE && !watchFile) {
      setFileError('Chưa có chữ ký !');
      return;
    }
    if (fileError) setFileError('');
    setReportStatus(statusNumber);
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (eReportRes) {
      setNote(eReportRes?.approve_note);
      setReportStatus(eReportRes?.approve_status || 0);
      setIsSubmitted(!eReportRes.can_approve || !eReportRes.key_valid);
    }
  }, [eReportRes]);

  if (!token) {
    return (
      <div className="p-home_invalid">
        <Heading type="h2" modifiers={['700', 'center']}>
          Đường dẫn không hợp lệ, xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  if (isNotFound) {
    return (
      <div className="p-home_invalid">
        <Heading type="h2" modifiers={['700', 'center']}>
          Không tìm thấy báo cáo, xin vui lòng thử lại!
        </Heading>
      </div>
    );
  }

  return (
    <PageLayout headerProps={{ title: 'BIÊN BẢN NGHIỆM THU ACTIVATION' }}>
      <FormProvider {...method}>
        <form noValidate>
          {(isFetching) && (
            <div className="p-home_loading">
              <Loading isShow variant="fullScreen" />
            </div>
          )}
          <div className="p-home">
            <div className="p-home_section">
              <InfoCard dataInfoCard={infoCard} />
            </div>
            <div className="p-home_section">
              <SectionTitle title="Kết quả thực hiện" />
            </div>
            <div className="u-mt-16">
              <ResultItem label="Bán hàng có quà tặng" amount={eReportRes?.analytics?.total_sales_has_gift || 0} />
              <ResultItem label="Bán hàng không quà tặng" amount={eReportRes?.analytics?.total_sales_no_gift || 0} />
              <ResultItem label="Số bill" amount={eReportRes?.analytics?.total_bill || 0} />
              <ResultItem label="Số quà" amount={eReportRes?.analytics?.total_gift || 0} />
            </div>
            <div className="p-home_section">
              <ProcessTitle
                title={`Doanh số: ${dataTableKPI.totalKpiCartonSafe} ${units.big} + ${dataTableKPI.totalKpiBottleCan} ${units.small}`}
              />

              <div className="p-home_mt">
                <Text modifiers={['16x24', '600']} content="Bán hàng có quà tặng" />
                <div className="p-home_mt">
                  <Table headerTable={dataHeaderReportKPITable} dataTable={dataTableKPI.table} />
                </div>
              </div>

              <div className="p-home_mt">
                <Text modifiers={['16x24', '600']} content="Bán hàng không có quà tặng" />
                <div className="p-home_mt">
                  <Table
                    headerTable={dataHeaderReportKPITable}
                    dataTable={dataTableKPI.tableNoneGift}
                  />
                </div>
              </div>

              <div className="p-home_mt">
                <ProcessTitle
                  title="Quà tặng"
                />
              </div>
              <div className="p-home_mt">
                <Table headerTable={dataPopup.header} dataTable={dataPopup.data} />
                {dataPopup?.comment && (
                  <div className="t-popupTable_note">
                    <Text type="span" modifiers={['eerieBlack', '14x20', '600']}>Ghi chú: </Text>
                    <Text type="span" modifiers={['eerieBlack', '14x20']}>{dataPopup?.comment}</Text>
                  </div>
                )}
              </div>

              <div className="p-home_mt">
                <ProcessTitle
                  title="Kết quả Sampling"
                />
              </div>
              <div className="p-home_mt">
                <Table headerTable={dataSamplingHeader} dataTable={dataSampling} />
              </div>
              {/* <div className="p-home_mt">
                <ProcessTitle
                  title="Nhân sự"
                  btnText="Xem chi tiết"
                  handleClick={() => setPopupType('employee')}
                />
              </div> */}
            </div>
            {(note && isSubmitted) && (
              <div className="p-home_section">
                <div className="p-home_mt">
                  <Text modifiers={['16x24', 'eerieBlack', '600']}>
                    {reportStatus === STATUS.APPROVE ? 'Nhận xét về kết quả thực hiện: ' : 'Lý do từ chối :'}
                  </Text>
                  <Text modifiers={['14x20', 'eerieBlack', '500']}>
                    {note}
                  </Text>
                </div>
              </div>
            )}
            {!isSubmitted ? (
              <>
                <div className="p-home_section">
                  <SectionTitle title="Ký xác nhận" />
                  <div className="p-home_mt">
                    <Controller
                      name="file"
                      render={({ field: { onChange } }) => (
                        <Signature
                          handleCompleted={(file) => {
                            onChange(file);
                            setFileError('');
                          }}
                          error={fileError}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="p-home_section">
                  <div className="p-home_btnGroup">
                    <div className="p-home_btnGroup-item">
                      <Button
                        modifiers={['seashell']}
                        iconName="dismiss"
                        onClick={() => handleClick(STATUS.NOT_APPROVE)}
                      >
                        <Text modifiers={['redOrange', '16x24', '600', 'center']}>
                          Từ chối
                        </Text>
                      </Button>
                    </div>
                    <div className="p-home_btnGroup-item">
                      <Button
                        modifiers={['green']}
                        iconName="checkmark"
                        onClick={() => handleClick(STATUS.APPROVE)}
                      >
                        <Text modifiers={['white', '16x24', '600', 'center']}>
                          Duyệt
                        </Text>
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="p-home_section">
                {reportStatus === STATUS.APPROVE && (
                  <Text modifiers={['laSalleGreen', '16x24', '600', 'center', 'capitalize']}>
                    Đã duyệt
                  </Text>
                )}
                {reportStatus === STATUS.NOT_APPROVE && (
                  <Text modifiers={['redOrange', '16x24', '600', 'center', 'capitalize']}>
                    Từ chối
                  </Text>
                )}
                {reportStatus === STATUS.NOT_APPROVE_YET && (
                  <Text modifiers={['stormcloud', '16x24', '600', 'center', 'capitalize']}>
                    Chưa duyệt
                  </Text>
                )}
              </div>
            )}
          </div>
          {/* <div className="p-home_popup">
            <PopupTable
              isOpen={!!popupType}
              handleClose={() => setPopupType('')}
              headerTable={dataPopup.header}
              dataTable={dataPopup.data}
              note={dataPopup?.comment}
            />
          </div> */}
          <NotifyModal
            isOpen={isOpen}
            title="Gửi thành công!"
            submitText="Đóng"
            handleSubmit={() => setIsOpen(false)}
            smallTitle
          />
          <NotifyModal
            isError
            isOpen={errorModal.open}
            title={errorModal.title}
            desc={errorModal.message || 'Đã có lỗi xảy ra. Vui lòng thử lại!'}
            submitText="Đóng"
            handleSubmit={() => setErrorModal({
              title: '',
              open: false,
              message: '',
            })}
          />
          <CustomModal
            showIconClose
            isOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
          >
            <div className="p-home_section p-home_mt">
              <Controller
                name="note"
                rules={{ required: reportStatus === STATUS.NOT_APPROVE && 'Xin hãy nhập lý do' }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <TextArea
                    rows={4}
                    value={value}
                    placeholder={reportStatus === STATUS.APPROVE ? 'Nhập nhận xét về kết quả thực hiện' : 'Nhập Lý do'}
                    label={reportStatus === STATUS.APPROVE ? 'Nhận xét' : 'Lý do từ chối'}
                    id="text"
                    handleOnchange={onChange}
                    error={error?.message}
                  />
                )}
              />
            </div>
            <div className="p-home_btnGroup p-home_mt">
              <div className="p-home_btnGroup-item">
                <Button
                  modifiers={['primary']}
                  onClick={
                    () => method.handleSubmit(
                      (data) => handleSubmit(data, reportStatus)
                    )()
                  }
                  loading={isLoading}
                >
                  <Text modifiers={['white', '16x24', '600', 'center']}>
                    Gửi
                  </Text>
                </Button>
              </div>
            </div>
          </CustomModal>
        </form>
      </FormProvider>
    </PageLayout>
  );
};
export default Home;
