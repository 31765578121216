import React from 'react';

import Text from 'components/atoms/Text';

interface SectionTitleProps {
  title?: string
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
}) => (
  <div className="m-sectionTitle">
    <Text modifiers={['16x24', '600', 'vividVermilion']} content={title} />
  </div>
);

export default SectionTitle;
